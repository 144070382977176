import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import HeadingAndTextarea from '../components/_V3/HeadingAndTextarea'
import PaymentMethods from '../components/_V3/PaymentMethods'
import DeliveryList from '../components/_V3/DeliveryList'
import ConnectWithUs from '../components/_V3/ConnectWithUs'
import WarrantyAndReturn from '../components/_V3/WarrantyAndReturn'

import Pres from '../pagesSections/index/ProductPresentation/assets/pres.png'
import PresKz from '../pagesSections/index/ProductPresentation/assets/pres_kz.png'
import PresBy from '../pagesSections/index/ProductPresentation/assets/pres_by.png'

import { getHeadingAndTextarea } from '../pages-data/_V3/delivery_payment/headingAndTextarea'
import { getPaymentMethods } from '../pages-data/_V3/delivery_payment/PaymentMethods/data'
import { getData as getDeliveryListData } from '../pages-data/_V3/delivery_payment/DeliveryList/data'
import { getData as getConnectWithUs } from '../pages-data/_V3/delivery_payment/ConnectWithUs/data'
import { getData as getWarrantyAndReturn } from '../pages-data/_V3/delivery_payment/WarrantyAndReturn/data'
import { externalLinks } from '../pages-data/_V2/common/links'

import { useLocalizationContext } from '../localization/useLocalizationContext'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/delivery_payment.module.scss'

export default function PageDeliveryPayment() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Quick Resto – Условия доставки и оплаты оборудования',
    'ru-KZ': 'Quick Resto Казахстан — Условия доставки и оплаты оборудования',
    'kz-KZ': 'Quick Resto Казахстан — Условия доставки и оплаты оборудования',
    'ru-BY': 'Quick Resto Беларусь — Условия доставки и оплаты оборудования',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}delivery-payment/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}delivery-payment/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}delivery-payment/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}delivery-payment/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Quick Resto - облачная система автоматизации ресторана, кафе, бара, которая экономит Ваше время и деньги. Принимаем деньги от населения и организаций в любом формате',
    'ru-KZ': 'Quick Resto — облачная система автоматизации ресторана, кафе, бара, которая экономит время и деньги. Принимаем деньги от населения и организаций в любом формате. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Quick Resto — облачная система автоматизации ресторана, кафе, бара, которая экономит время и деньги. Принимаем деньги от населения и организаций в любом формате. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Quick Resto — облачная система автоматизации ресторана, кафе, бара, которая экономит время и деньги. Принимаем деньги от населения и организаций в любом формате. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Pres}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresBy}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: metaUrl[localizationContext.locale],
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
  ]

  const deliveryList = getDeliveryListData(localizationContext.locale)
  const connectWithUs = getConnectWithUs(localizationContext.locale)
  const warrantyAndReturn = getWarrantyAndReturn(localizationContext.locale)

  return (
    <DefaultLayout
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
        pageStyles.pageWrapper,
        pageStyles.pageGray,
        pageStyles.deliveryPaymentPage,
      )}
      linkCanonical={currentCanonicalLink}
      footerClassName={styles.automation__footer}
    >
      <HeadingAndTextarea
        data={getHeadingAndTextarea(localizationContext.locale)}
        className={styles.delivery__headingAndTextarea}
      />

      <PaymentMethods
        data={getPaymentMethods(localizationContext.locale)}
        className={pageStyles.pageSection}
      />

      <DeliveryList data={deliveryList} className={pageStyles.pageSection} />

      <ConnectWithUs data={connectWithUs} className={pageStyles.pageSection} />

      <WarrantyAndReturn
        data={warrantyAndReturn}
        className={classNames(
          pageStyles.pageSection,
          styles.delivery__warrantyAndReturn,
        )}
      />
    </DefaultLayout>
  )
}
