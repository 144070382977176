import * as React from "react"
import { SVGProps } from "react"

const Email = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="54"
		height="54"
		viewBox="0 0 54 54"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_12564_269348"
			mask-type="alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="54"
			height="54"
		>
			<path
				d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z"
				fill="url(#paint0_linear_12564_269348)"
			/>
		</mask>
		<g mask="url(#mask0_12564_269348)">
			<rect
				x="-9.81787"
				width="73.6346"
				height="53.9987"
				rx="5.1035"
				fill="url(#paint1_linear_12564_269348)"
			/>
			<mask
				id="mask1_12564_269348"
				mask="alpha"
				maskUnits="userSpaceOnUse"
				x="-10"
				y="0"
				width="74"
				height="54"
			>
				<rect
					x="-9.81738"
					width="73.6346"
					height="53.9987"
					rx="5.1035"
					fill="url(#paint2_linear_12564_269348)"
				/>
			</mask>
			<g mask="url(#mask1_12564_269348)">
				<path
					d="M27.001 53.9987H63.8183L33.0155 23.1959C29.6938 19.8742 24.3082 19.8742 20.9865 23.1959L-9.81634 53.9987H27.001Z"
					fill="url(#paint3_linear_12564_269348)"
				/>
				<path
					d="M27.001 0.000542362H63.8183L33.0155 30.8033C29.6938 34.1251 24.3082 34.1251 20.9865 30.8033L-9.81634 0.000542362H27.001Z"
					fill="url(#paint4_linear_12564_269348)"
				/>
			</g>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_12564_269348"
				x1="27.054"
				y1="0.054"
				x2="27.054"
				y2="53.514"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#2AABEE" />
				<stop offset="1" stopColor="#229ED9" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_12564_269348"
				x1="26.9994"
				y1="8.04644e-07"
				x2="26.9994"
				y2="50.746"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FBA81E" />
				<stop offset="1" stopColor="#FC708C" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_12564_269348"
				x1="26.9999"
				y1="8.04644e-07"
				x2="26.9999"
				y2="50.746"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FBA81E" />
				<stop offset="1" stopColor="#FC708C" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_12564_269348"
				x1="27.001"
				y1="30.8919"
				x2="27.001"
				y2="68.2768"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FBA81E" />
				<stop offset="1" stopColor="#FC708C" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_12564_269348"
				x1="53.4708"
				y1="17.8836"
				x2="-9.67375"
				y2="-0.489518"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFEB3B" />
				<stop offset="1" stopColor="#FC934A" />
			</linearGradient>
		</defs>
	</svg>
)

export default Email
