import React from "react"

import CreditCard from "./assets/CreditCard"
import MoneyTransfer from "./assets/MoneyTransfer"
import Cash from "./assets/Cash"
import {Locales} from "../../../../localization/types";

export const getPaymentMethods = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return {
			heading: "Төлеу тәсілдері",
			textarea: "Халықтан және ұйымдардан ақшаны кез келген форматта қабылдаймыз",
			methods: [
				{
					id: 0,
					mediaContent: <CreditCard />,
					heading: "Картамен",
					textarea: (
						<>
							Жай ғана бассаңыз,
							<br />төлем өтеді
						</>
					),
				},
				{
					id: 1,
					mediaContent: <MoneyTransfer />,
					heading: "Аударыммен",
					textarea: (
						<>
							Онда барлығы бір <br />
							банк арқылы жасалады
						</>
					),
				},
				{
					id: 2,
					mediaContent: <Cash />,
					heading: "Қолма-қол ақшамен",
					textarea: (
						<>
							Есептеп жатқанда, <br/>
							қағаз ақшамен судырлаймыз
						</>
					),
				},
			],
		}
	}

	return {
		heading: "Способы оплаты",
		textarea: "Принимаем деньги от населения и организаций в любом формате",
		methods: [
			{
				id: 0,
				mediaContent: <CreditCard />,
				heading: "Картой",
				textarea: (
					<>
						Просто приложи
						<br />и платёж пройдёт
					</>
				),
			},
			{
				id: 1,
				mediaContent: <MoneyTransfer />,
				heading: "Переводом",
				textarea: (
					<>
						Там всё через один
						<br />
						банк делается
					</>
				),
			},
			{
				id: 2,
				mediaContent: <Cash />,
				heading: "Наличными",
				textarea: (
					<>
						Пошуршим купюрами,
						<br />
						пока будем пересчитывать
					</>
				),
			},
		],
	}
}
export default {
	heading: "Способы оплаты",
	textarea: "Принимаем деньги от населения и организаций в любом формате",
	methods: [
		{
			id: 0,
			mediaContent: <CreditCard />,
			heading: "Картой",
			textarea: (
				<>
					Просто приложи
					<br />и платёж пройдёт
				</>
			),
		},
		{
			id: 1,
			mediaContent: <MoneyTransfer />,
			heading: "Переводом",
			textarea: (
				<>
					Там всё через один
					<br />
					банк делается
				</>
			),
		},
		{
			id: 2,
			mediaContent: <Cash />,
			heading: "Наличными",
			textarea: (
				<>
					Пошуршим купюрами,
					<br />
					пока будем пересчитывать
				</>
			),
		},
	],
}
