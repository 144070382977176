import * as React from "react"
import { SVGProps } from "react"

const Phone = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="54"
		height="54"
		viewBox="0 0 54 54"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="27" cy="27" r="27" fill="url(#paint0_linear_12564_269347)" />
		<mask
			id="mask0_12564_269347"
			mask-type="alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="54"
			height="54"
		>
			<circle cx="27" cy="27" r="27" fill="url(#paint1_linear_12564_269347)" />
		</mask>
		<g mask="url(#mask0_12564_269347)">
			<rect
				x="13.04"
				y="13.781"
				width="27.9167"
				height="47.6445"
				rx="3.94613"
				fill="#D0EBFF"
			/>
			<path
				d="M22.6689 16.9751C22.6689 16.2042 23.2939 15.5792 24.0648 15.5792H29.9337C30.7046 15.5792 31.3295 16.2042 31.3295 16.9751C31.3295 17.746 30.7046 18.3709 29.9337 18.3709H24.0648C23.2939 18.3709 22.6689 17.746 22.6689 16.9751Z"
				fill="#3B71E7"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_12564_269347"
				x1="27"
				y1="0"
				x2="27"
				y2="54"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6CDBDB" />
				<stop offset="1" stopColor="#1C7ED6" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_12564_269347"
				x1="54.7037"
				y1="-1.37051e-06"
				x2="1.6303e-06"
				y2="54"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#3360E8" />
				<stop offset="1" stopColor="#3021E5" />
			</linearGradient>
		</defs>
	</svg>
)

export default Phone
