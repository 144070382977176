import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: {
		heading: string | JSX.Element
		textarea: string | JSX.Element
		points: [
			{
				id: number
				mediaContent: JSX.Element
				heading: string | JSX.Element
				textarea: string | JSX.Element
				content: string | JSX.Element
			}
		]
	}
}

// Компонент инфы о доставке
// Пример /delivery-payment/
export default function DeliveryList(props: Props) {
	const data = props.data || {}

	return (
		<section className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>{data.heading}</h2>

			<p className={styles.textarea}>{data.textarea}</p>

			<div className={styles.list}>
				{data.points.map(point => (
					<div key={point.id} className={styles.listItem}>
						{point.mediaContent}
						<h3 className={styles.listItem__heading}>{point.heading}</h3>
						<p className={styles.listItem__textarea}>{point.textarea}</p>
						<div className={styles.listItem__content}>{point.content}</div>
					</div>
				))}
			</div>
		</section>
	)
}
