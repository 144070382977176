import * as React from "react"
import { SVGProps } from "react"

const Cash = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="200"
		height="200"
		viewBox="0 0 200 200"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_12678_270508)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.998 68.916C20.998 89.3439 33.8933 106.76 51.9876 113.468C52.2904 113.58 52.5235 113.83 52.6149 114.139C57.4197 130.437 70.3186 143.266 86.6584 147.972C86.9631 148.06 87.2109 148.285 87.3277 148.579C94.2944 166.155 111.447 178.584 131.502 178.584C157.735 178.584 179.002 157.318 179.002 131.084C179.002 111.073 166.628 93.9524 149.114 86.9561C148.82 86.8385 148.595 86.5901 148.508 86.2852C143.699 69.42 130.244 56.1999 113.24 51.7246C112.928 51.6424 112.672 51.4168 112.551 51.1174C105.508 33.7023 88.4378 21.416 68.498 21.416C42.2645 21.416 20.998 42.6825 20.998 68.916Z"
				fill="url(#paint0_linear_12678_270508)"
			/>
			<path
				d="M120.568 36.8237C85.0751 25.0267 46.7393 44.2356 34.9423 79.7281C23.1452 115.221 42.3542 153.556 77.8467 165.353C113.339 177.151 151.675 157.942 163.472 122.449C167.843 109.298 167.957 95.7571 164.564 83.3202"
				stroke="#2A2D33"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M88.4584 86.8064L36.5643 75.3959"
				stroke="#2A2D33"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M88.0839 136.78C74.909 139.914 63.0177 141.158 52.3524 140.996"
				stroke="#2A2D33"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M110.107 83.4214L119.053 97.3728C121.388 101.015 126.36 101.839 129.745 99.1445C132.702 96.7912 133.374 92.5705 131.295 89.415L124.749 79.4812C122.509 76.0822 119.683 72.8879 115.775 71.7501C112.582 70.8207 109.182 70.6688 105.865 71.3505C100.511 72.4509 95.8133 75.633 92.8055 80.1969C89.7978 84.7609 88.7263 90.3326 89.8267 95.6866C90.6584 99.7337 92.6797 103.406 95.5881 106.256"
				stroke="#2A2D33"
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<path
				d="M116.036 70.9387C123.735 55.5774 130.2 30.9958 132.804 20.8054C141.716 23.2559 166.071 33.8662 175.715 48.0774C166.837 58.1236 150.409 76.6645 138.406 97.4287M110.057 83.5536C102.755 100.643 81.6488 122.526 74.7683 130.463C74.7683 130.463 88.5027 135.905 96.6781 140.6C104.76 145.242 116.262 154.239 116.262 154.239C116.862 150.037 117.781 146.021 118.987 141.678M168.775 56.0036C176.684 62.4793 181.253 73.9334 181.253 73.9334C171.436 77.5632 160.918 84.4475 152.583 94.3559"
				stroke="#2A2D33"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="113.418"
				y="124.947"
				width="29.134"
				height="15.4706"
				rx="7.73528"
				transform="rotate(8.97006 113.418 124.947)"
				stroke="#2A2D33"
				strokeWidth="3"
			/>
			<rect
				x="120.672"
				y="110.402"
				width="31.4707"
				height="15.4706"
				rx="7.73528"
				transform="rotate(8.97006 120.672 110.402)"
				stroke="#2A2D33"
				strokeWidth="3"
			/>
			<rect
				x="130.329"
				y="96.3066"
				width="31.4707"
				height="15.4706"
				rx="7.73528"
				transform="rotate(8.97006 130.329 96.3066)"
				stroke="#2A2D33"
				strokeWidth="3"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_12678_270508"
				x1="134.805"
				y1="5.70509"
				x2="57.6662"
				y2="184.777"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FDD835" />
				<stop offset="1" stopColor="#FF80AB" stopOpacity="0.2" />
			</linearGradient>
			<clipPath id="clip0_12678_270508">
				<rect width="200" height="200" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default Cash
