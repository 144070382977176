import React from "react"

import WarranyAndReturn from "./assets/WarranyAndReturn"

import styles from "./styles.module.scss"
import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			mediaContent: <WarranyAndReturn />,
			heading: (
				<span className={styles.heading}>
					Гарантия и возврат
				</span>
			),
			content: (
				<ul className={styles.ul}>
					<li>
						Гарантийный срок исчисляется с момента подписания Сторонами товарной
						накладной. Гарантия 1 год от производителя.
					</li>
					<li>
						Доставка оборудования Поставщику для проведения обслуживания и возврат
						оборудования осуществляется силами и за счет Покупателя.
					</li>
					<li>
						<span>
							В случае необходимости закупки запасных частей у производителя
							оборудования, срок обслуживания может быть увеличен Поставщиком в
							одностороннем порядке, с обязательным уведомлением об этом Покупателя
							и указанием срока окончания ремонта, в момент приемки оборудования
							в ремонт, либо в течение одного дня, с момента окончания диагностики,
							принятого в ремонт оборудования.
						</span>
					</li>
				</ul>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			mediaContent: <WarranyAndReturn />,
			heading: (
				<span className={styles.heading}>
					Кепілдік және қайтару
				</span>
			),
			content: (
				<ul className={styles.ul}>
					<li>
						Кепілдік мерзімі Тараптар тауар жүкқұжатына қол қойған сәттен бастап есептеледі. Өндірушіден кепілдік 1 жыл.
					</li>
					<li>
						Қызмет көрсету үшін жабдықты Жеткізушіге жеткізу және жабдықты қайтару Сатып алушының күшімен және есебінен жүзеге асырылады.
					</li>
					<li>
						<span>
							Жабдықты өндірушіден қосалқы бөлшектерді сатып алу қажет болған жағдайда, қызмет көрсету
							мерзімін Жеткізуші біржақты тәртіппен ұлғайта алады, бұл туралы міндетті түрде Сатып алушыны
							ескертеді және жөндеу аяқталуы мерзімін көрсетеді, жабдықты жөндеуге қабылдау сәтінде, немесе
							бір күн ішінде, жөндеуге қабылданған жабдықтың диагностикасы аяқталған сәттен бастап.
						</span>
					</li>
				</ul>
			),
		}
	}

	if (locale === 'ru-BY') {
		return {
			mediaContent: <WarranyAndReturn />,
			heading: (
				<span className={styles.heading}>
					Гарантия и возврат
				</span>
			),
			content: (
				<ul className={styles.ul}>
					<li>
						Гарантийный срок исчисляется с момента подписания Сторонами товарной
						накладной (ТОРГ-12). Гарантия 1 год от производителя.
					</li>
					<li>
						Доставка оборудования Поставщику для проведения обслуживания и возврат
						оборудования осуществляется силами и за счет Покупателя.
					</li>
					<li>
						<span>
							В случае необходимости закупки запасных частей у производителя
							оборудования, срок обслуживания может быть увеличен Поставщиком в
							одностороннем порядке, с обязательным уведомлением об этом Покупателя
							и указанием срока окончания ремонта, в момент приемки оборудования
							в ремонт, либо в течение одного дня, с момента окончания диагностики,
							принятого в ремонт оборудования.
						</span>
					</li>
				</ul>
			),
		}
	}


	return {
		mediaContent: <WarranyAndReturn />,
		heading: (
			<span className={styles.heading}>
				Гарантия и возврат
			</span>
		),
		content: (
			<ul className={styles.ul}>
				<li>
					Гарантийный срок исчисляется с момента подписания Сторонами товарной
					накладной (ТОРГ-12). Гарантия 1 год от производителя.
				</li>
				<li>
					Доставка оборудования Поставщику для проведения обслуживания и возврат
					оборудования осуществляется силами и за счет Покупателя.
				</li>
				<li>
				<span>
					В случае необходимости закупки запасных частей у производителя
					оборудования, срок обслуживания может быть увеличен Поставщиком в
					одностороннем порядке, с обязательным уведомлением об этом Покупателя
					и указанием срока окончания ремонта, в момент приемки оборудования
					в ремонт, либо в течение одного дня, с момента окончания диагностики,
					принятого в ремонт оборудования. Поставщик осуществляет гарантийное
					обслуживание по адресу: 125167, Москва, Ленинградский пр. 36, стр. 30,
					пом. 96.
				</span>
				</li>
			</ul>
		),
	}
}

{
	/* <Link to={pagesLinks.contacts.href}>адресу</Link> */
}
