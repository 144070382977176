import * as React from "react"
import { SVGProps } from "react"

const MoneyTransfer = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="200"
		height="200"
		viewBox="0 0 200 200"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M31.1064 131.778C31.1064 156.51 61.8375 176.56 99.7461 176.56C137.655 176.56 168.386 156.51 168.386 131.778C168.386 119.81 161.19 108.938 149.466 100.904C148.835 100.472 148.835 99.5285 149.466 99.0958C161.19 91.062 168.386 80.1904 168.386 68.2223C168.386 43.4899 137.655 23.4404 99.7461 23.4404C61.8375 23.4404 31.1064 43.4899 31.1064 68.2223C31.1064 80.1904 38.3025 91.062 50.0258 99.0958C50.6572 99.5285 50.6572 100.472 50.0258 100.904C38.3025 108.938 31.1064 119.81 31.1064 131.778Z"
			fill="url(#paint0_linear_12678_270507)"
		/>
		<rect
			x="76.7861"
			y="89.2856"
			width="98.2143"
			height="71.4286"
			rx="9.23376"
			stroke="#2A2D33"
			strokeWidth="3"
		/>
		<rect
			x="83.9287"
			y="116.071"
			width="22.3214"
			height="17.8571"
			rx="5"
			stroke="#2A2D33"
			strokeWidth="3"
		/>
		<circle
			cx="156.697"
			cy="107.589"
			r="11.1607"
			stroke="#2A2D33"
			strokeWidth="3"
		/>
		<path
			d="M76.8019 110.714H34.2338C29.1341 110.714 25 106.58 25 101.48V48.5194C25 43.4197 29.1341 39.2856 34.2338 39.2856H113.981C119.08 39.2856 123.214 43.4197 123.214 48.5193V82.11"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M123.214 57.1428H25V74.9999H116.071"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M62.5 160.714H34.2338C29.1341 160.714 25 156.58 25 151.48V130.357M137.5 39.2856H165.766C170.866 39.2856 175 43.4197 175 48.5193V69.6427"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M155.399 21.3857L137.5 39.2854L155.399 57.185"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M44.6328 178.614L62.5325 160.714L44.6328 142.815"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_12678_270507"
				x1="168.386"
				y1="176.56"
				x2="45.4772"
				y2="12.6635"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FF9CA2" />
				<stop offset="0.591667" stopColor="#A839FF" stopOpacity="0.35" />
				<stop offset="1" stopColor="#4541FF" stopOpacity="0.1" />
			</linearGradient>
		</defs>
	</svg>
)

export default MoneyTransfer
