import * as React from "react"
import { SVGProps } from "react"

const KazakhstanPoints = (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        width="283"
        height="200"
        viewBox="0 0 283 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Group 1112608">
            <g id="Group 580680859">
                <path
                    id="Vector"
                    d="M54.5401 70.1998L62.1243 70.1998L73.2051 80.9781L115.489 80.9781L115.489 65.2478L109.949 65.2478L109.949 53.8928L123.069 53.8928L150.771 37L159.226 37L159.226 48.0666L178.184 48.0666L188.971 37L210.624 58.6252L257.5 58.6252L257.5 64.9594L251.378 71.074L256.043 80.9781L241.754 86.5114L241.754 102.237L228.341 108.933L241.461 127.574L241.461 135.144L191.886 135.144L191.886 141.551L182.556 141.551L167.103 162.229L153.103 162.229L142.815 141.263L120.154 141.263L112.281 134.563L97.1173 125.244L72.0366 131.652L72.0366 161.067L64.4569 157.863L54.5401 158.151L44.9166 134.27L48.9997 130.197L60.3738 130.197L60.3738 115.926L53.0828 108.059L37.9189 112.722L32.9605 99.6146L25.5 99.6146L31.5032 80.6852L54.5401 70.1998Z"
                    fill="white"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <g id="Vector_2">
                    <path 
                        d="M60.3743 119.798L68.1922 119.798L72.4724 123.807L76.9359 130.398" 
                        fill="white" 
                    />
                    <path 
                        d="M60.3743 119.798L68.1922 119.798L72.4724 123.807L76.9359 130.398" 
                        stroke="black" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_3">
                    <path 
                        d="M53.0837 108.058L68.6555 101.688L72.9219 91.7744L78.3982 88.6988L78.3982 80.9778" 
                        fill="white"
                    />
                    <path 
                        d="M53.0837 108.058L68.6555 101.688L72.9219 91.7744L78.3982 88.6988L78.3982 80.9778"
                        stroke="black" 
                        strokeWidth="2"
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_4">
                    <path
                        d="M97.1194 125.244L115.019 116.045L137.607 108.059L154.718 109.551L173.883 111.601L195.101 108.059L211.186 99.6146L221.111 97.5871L221.111 89.381L229.667 82.8911L241.756 86.5113"
                        fill="white"
                    />
                    <path
                        d="M97.1194 125.244L115.019 116.045L137.607 108.059L154.718 109.551L173.883 111.601L195.101 108.059L211.186 99.6146L221.111 97.5871L221.111 89.381L229.667 82.8911L241.756 86.5113"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_5">
                    <path 
                        d="M203.714 51.7236L198.178 65.1152L200.946 78.786L208.448 85.6237L211.528 90.7497L211.184 99.6148" 
                        fill="white"/>
                    <path 
                        d="M203.714 51.7236L198.178 65.1152L200.946 78.786L208.448 85.6237L211.528 90.7497L211.184 99.6148"
                        stroke="black"
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_6">
                    <path 
                        d="M214.265 135.144L204.857 126.979L202.63 117.07L195.788 111.945L195.1 108.059" 
                        fill="white"
                    />
                    <path 
                        d="M214.265 135.144L204.857 126.979L202.63 117.07L195.788 111.945L195.1 108.059"
                        stroke="black" 
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_7">
                    <path 
                        d="M178.331 147.208L172.172 135.144L172.172 126.297L175.251 118.439L170.797 111.271" 
                        fill="white"
                    />
                    <path 
                        d="M178.331 147.208L172.172 135.144L172.172 126.297L175.251 118.439L170.797 111.271" 
                        stroke="black" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_8">
                    <path 
                        d="M107.108 80.9778L109.148 87.193L116.072 91.6645L116.072 99.6143L121.777 108.058L119.61 114.42" 
                        fill="white"
                    />
                    <path 
                        d="M107.108 80.9778L109.148 87.193L116.072 91.6645L116.072 99.6143L121.777 108.058L119.61 114.42" 
                        stroke="black" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_9">
                    <path 
                        d="M169.436 49.3523L170.797 60.3365L166.178 72.9501L170.797 77.4261L178.331 84.3416L191.103 86.3783L200.946 78.7854" 
                        fill="white"
                    />
                    <path 
                        d="M169.436 49.3523L170.797 60.3365L166.178 72.9501L170.797 77.4261L178.331 84.3416L191.103 86.3783L200.946 78.7854" 
                        stroke="black" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </g>
                <g id="Vector_10">
                    <path 
                        d="M115.49 75.6687L125.851 79.0555L131.144 84.749L144.997 82.8908L155.996 69.696L166.178 72.9501" 
                        fill="white"
                    />
                    <path 
                        d="M115.49 75.6687L125.851 79.0555L131.144 84.749L144.997 82.8908L155.996 69.696L166.178 72.9501" 
                        stroke="black"
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <g id="Group 1112599">
                <g id="Group 1112598">
                    <rect 
                        id="Rectangle 1742" 
                        x="75.9258" y="110.031" 
                        width="20.273" 
                        height="16.9399"
                        transform="rotate(-23.409 75.9258 110.031)" 
                        fill="white"
                    />
                    <path 
                        id="Rectangle 1743" 
                        d="M101.418 104.293L96.4622 106.439L101.261 117.523L111.133 113.249L108.252 106.594L101.418 104.293Z" 
                        fill="white"
                    />
                </g>
                <g id="Group 1112596">
                    <path 
                        id="Rectangle 1740" 
                        d="M95.3229 120.093L101.26 117.522L94.5303 101.977L75.9258 110.031L82.6558 125.577L86.1955 124.044" 
                        stroke="#2A2D33"
                        strokeWidth="2"
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                    <path 
                        id="Rectangle 1741" 
                        d="M109.567 113.927L111.133 113.249L108.251 106.591L101.398 104.302L96.4622 106.438L101.261 117.523L102.827 116.845" 
                        stroke="#2A2D33"
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                    <circle
                        id="Ellipse 738"
                        cx="89.1498"
                        cy="122.765" 
                        r="3.05997" 
                        transform="rotate(-23.409 89.1498 122.765)" 
                        fill="white" 
                        stroke="#2A2D33" 
                        strokeWidth="2"
                    />
                    <circle 
                        id="Ellipse 739"
                        cx="106.197" 
                        cy="115.386" 
                        r="3.05997" 
                        transform="rotate(-23.409 106.197 115.386)" 
                        fill="white" 
                        stroke="#2A2D33"
                        strokeWidth="2"
                    />
                </g>
            </g>
            <g id="Group 1112600">
                <g id="Group 1112598_2">
                    <rect 
                        id="Rectangle 1742_2" 
                        width="20.273"
                        height="16.9399" 
                        transform="matrix(-0.999999 0.00175161 0.00175161 0.999999 250.19 37.252)" 
                        fill="white"
                    />
                    <path 
                        id="Rectangle 1743_2" 
                        d="M224.525 42.1591L229.925 42.1496L229.947 54.2279L219.189 54.2468L219.176 46.9952L224.525 42.1591Z" 
                        fill="white"
                    />
                </g>
                <g id="Group 1112596_2">
                    <path 
                        id="Rectangle 1740_2" 
                        d="M236.417 54.2159L229.947 54.2272L229.917 37.2875L250.19 37.252L250.22 54.1917L246.363 54.1985" 
                        stroke="#2A2D33"
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                    <path 
                        id="Rectangle 1741_2" 
                        d="M220.896 54.2435L219.189 54.2465L219.176 46.991L224.547 42.1586L229.926 42.1492L229.947 54.2277L228.24 54.2307" 
                        stroke="#2A2D33" 
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle 
                        id="Ellipse 738_2"
                        cx="3.05997" 
                        cy="3.05997"
                        r="3.05997" 
                        transform="matrix(-0.999998 0.00175178 0.00175178 0.999998 246.198 51.1384)" 
                        fill="white"
                        stroke="#2A2D33" 
                        strokeWidth="2"
                    />
                    <circle 
                        id="Ellipse 739_2"
                        cx="3.05997" 
                        cy="3.05997" 
                        r="3.05997" 
                        transform="matrix(-0.999998 0.00175178 0.00175178 0.999998 227.623 51.1716)"
                        fill="white" 
                        stroke="#2A2D33"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default KazakhstanPoints
