import React from "react"
import classNames from "classnames"

import ChevronRight from "../../../assets/_V2/svg/ChevronRight"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: {
		subheading: string | JSX.Element
		heading: string | JSX.Element
		list: [
			{
				id: number
				mediaContent: JSX.Element
				link: {
					text: string | JSX.Element
					url: string
					rel?: string
				}
			}
		]
	}
}

// Компонент карточек со связью с QuickResto
// Пример /delivery-payment/
export default function ConnectWithUs(props: Props) {
	const data = props.data || {}

	return (
		<section className={classNames(styles.section, props.className)}>
			<div className={styles.content__row}>
				<div className={styles.content__column}>
					<span className={styles.subheading}>{data.subheading}</span>

					<h2 className={styles.heading}>{data.heading}</h2>
				</div>
				<div className={styles.content__column}>
					<div className={styles.list}>
						{data.list.map(item => (
							<a key={item.id} href={item.link.url} rel={item.link.rel} className={styles.listItem}>
								{item.mediaContent}
								<span className={styles.listItem__text}>
									{item.link.text}
									<ChevronRight className={styles.listItem__textIcon} />
								</span>
							</a>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}
