import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: {
		mediaContent: JSX.Element
		subheading?: string | JSX.Element
		heading: string | JSX.Element
		content: string | JSX.Element
	}
}

// Компонент точек доставки
// Пример /delivery-payment/
export default function WarrantyAndReturn(props: Props) {
	const data = props.data || {}

	return (
		<section className={classNames(styles.section, props.className)}>
			<div className={styles.content__row}>
				<div className={styles.content__column}>{data.mediaContent}</div>
				<div className={styles.content__column}>
					{ data.subheading && <span  className={styles.subheading}>{data.subheading}</span> }

					<h2 className={styles.heading}>{data.heading}</h2>
					<div className={styles.content}>{data.content}</div>
				</div>
			</div>
		</section>
	)
}
