import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: {
		heading: string | JSX.Element
		textarea: string | JSX.Element
		methods: [
			{
				id: number
				mediaContent: JSX.Element
				heading: string | JSX.Element
				textarea: string | JSX.Element
			}
		]
	}
}

// Компонент способов оплаты
// Пример /delivery-payment/
export default function PaymentMethods(props: Props) {
	const data = props.data || {}

	return (
		<section className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>{data.heading}</h2>

			<p className={styles.textarea}>{data.textarea}</p>

			<div className={styles.list}>
				{data.methods.map(method => (
					<div key={method.id} className={styles.listItem}>
						{method.mediaContent}
						<h3 className={styles.listItem__heading}>{method.heading}</h3>
						<p className={styles.listItem__textarea}>{method.textarea}</p>
					</div>
				))}
			</div>
		</section>
	)
}
