import * as React from "react"
import { SVGProps } from "react"

const Telegram = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="54"
		height="54"
		viewBox="0 0 54 54"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z"
			fill="url(#paint0_linear_12564_269350)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.222 26.7147C20.0941 23.2857 25.3427 21.0249 27.9679 19.9323C35.4658 16.8138 37.0242 16.2717 38.0394 16.2538C38.263 16.2501 38.7625 16.3078 39.0859 16.5681C39.3586 16.7895 39.4342 17.0892 39.4699 17.2993C39.5248 17.6507 39.54 18.0072 39.5152 18.362C39.1086 22.6312 37.3509 32.9938 36.4561 37.7739C36.0781 39.7968 35.3324 40.4739 34.611 40.542C33.045 40.6861 31.8526 39.5057 30.3336 38.5099C27.9576 36.9526 26.6152 35.9827 24.3083 34.4626C21.6423 32.706 23.3709 31.7405 24.8899 30.1626C25.2873 29.7495 32.1961 23.4666 32.3295 22.8958C32.3462 22.8246 32.3618 22.5589 32.2036 22.4185C32.0454 22.2781 31.8132 22.3261 31.6447 22.3645C31.4064 22.4185 27.6102 24.9277 20.2561 29.8921C19.1783 30.6319 18.2023 30.9919 17.3283 30.9721C16.3638 30.951 14.5095 30.4267 13.1308 29.9785C11.4401 29.4288 10.096 29.1382 10.2148 28.2051C10.275 27.7198 10.944 27.223 12.222 26.7147Z"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_12564_269350"
				x1="27.054"
				y1="0.054"
				x2="27.054"
				y2="53.514"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#2AABEE" />
				<stop offset="1" stopColor="#229ED9" />
			</linearGradient>
		</defs>
	</svg>
)

export default Telegram
