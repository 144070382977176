import * as React from "react"
import { SVGProps } from "react"

const CreditCard = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="200"
		height="200"
		viewBox="0 0 200 200"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M105.846 180.745C103.594 185.26 96.4056 185.26 94.1542 180.745C78.5907 149.533 50.4672 121.409 19.2555 105.846C14.7404 103.594 14.7404 96.4056 19.2555 94.1542C50.4672 78.5907 78.5907 50.4672 94.1542 19.2554C96.4056 14.7404 103.594 14.7404 105.846 19.2554C121.409 50.4672 149.533 78.5907 180.745 94.1542C185.26 96.4056 185.26 103.594 180.745 105.846C149.533 121.409 121.409 149.533 105.846 180.745Z"
			fill="url(#paint0_linear_12678_270506)"
		/>
		<path
			d="M126.417 106.233L139.765 56.4191C141.05 51.6225 138.203 46.6921 133.407 45.4069L58.3989 25.3086C53.6022 24.0234 48.6719 26.8699 47.3867 31.6665L34.0391 81.4803C32.7538 86.277 35.6003 91.2073 40.397 92.4925L98.6392 108.098"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<rect
			x="49.6831"
			y="49.9756"
			width="21.7356"
			height="17.3885"
			rx="5"
			transform="rotate(15 49.6831 49.9756)"
			stroke="#2A2D33"
			strokeWidth="3"
		/>
		<circle
			cx="120.263"
			cy="60.3373"
			r="10.8678"
			transform="rotate(15 120.263 60.3373)"
			stroke="#2A2D33"
			strokeWidth="3"
		/>
		<path
			d="M135.156 129.231H134.891C129.909 129.231 125.584 125.795 124.458 120.941L117.375 90.4307C116.377 86.1306 112.546 83.0866 108.131 83.0866C102.89 83.0866 98.6411 87.3354 98.6411 92.5765L98.6411 131.87C98.6411 134.606 99.3617 137.294 100.73 139.664L110.459 156.504C111.828 158.873 112.548 161.561 112.548 164.298V171.105C112.548 173.256 114.292 175 116.444 175H162.371C164.522 175 166.266 173.256 166.266 171.105V106.07C166.266 101.949 164.633 97.9966 161.726 95.0767L136.311 69.554"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M93.4712 113.417C88.7687 113.417 84.9565 117.229 84.9565 121.932C84.9565 126.634 88.7687 130.446 93.4712 130.446"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M98.2981 113.417H85.6802C80.9777 113.417 77.1655 109.605 77.1655 104.903C77.1655 104.044 77.2925 103.216 77.5287 102.434"
			stroke="#2A2D33"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_12678_270506"
				x1="184.131"
				y1="187.647"
				x2="15.8692"
				y2="15.8692"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#74C0FC" stopOpacity="0.2" />
				<stop offset="0.4875" stopColor="#01C7DA" stopOpacity="0.5" />
				<stop offset="1" stopColor="#63E6BE" />
			</linearGradient>
		</defs>
	</svg>
)

export default CreditCard
