import * as React from "react"
import { SVGProps } from "react"

const WhatsApp = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="54"
		height="54"
		viewBox="0 0 54 54"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M27.2112 1.41744e-06C22.3867 -0.00156157 17.6498 1.28951 13.4933 3.73895C9.33683 6.18838 5.91249 9.7067 3.57649 13.928C1.24049 18.1493 0.0781444 22.9193 0.210335 27.7421C0.342526 32.5648 1.76444 37.264 4.32817 41.351L4.97276 42.3753L2.26635 52.3311L12.4827 49.6512L13.4717 50.2384C17.6322 52.7035 22.3797 54.0028 27.2156 54C34.3167 53.9092 41.0961 51.0246 46.0856 45.971C51.075 40.9174 53.8727 34.1016 53.8727 27C53.8727 19.8984 51.075 13.0826 46.0856 8.02902C41.0961 2.97541 34.3167 0.0908075 27.2156 1.41744e-06H27.2112Z"
			fill="url(#paint0_linear_12564_269349)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.0839 13.4076C18.4746 12.0566 17.8344 12.0301 17.2561 12.0081H15.702C15.2908 12.0186 14.8862 12.1143 14.5139 12.2891C14.1415 12.4638 13.8094 12.7139 13.5386 13.0235C12.6237 13.8891 11.8986 14.9353 11.4091 16.0958C10.9197 17.2564 10.6766 18.5059 10.6953 19.7652C10.6953 23.7387 13.6048 27.6151 14.011 28.1537C14.4172 28.6924 19.6269 37.1515 27.8742 40.4054C34.7307 43.1074 36.1259 42.5688 37.6137 42.4363C39.1016 42.3039 42.4173 40.4716 43.0928 38.5776C43.7683 36.6835 43.7683 35.0456 43.5652 34.7189C43.3621 34.3922 42.8235 34.1758 42.0111 33.7696C41.1987 33.3635 37.2075 31.4032 36.4658 31.1206C35.7241 30.8381 35.1811 30.7145 34.638 31.5268C34.095 32.3392 32.5409 34.1758 32.0685 34.71C31.5961 35.2443 31.1236 35.3193 30.3025 34.9131C27.9088 33.9598 25.6992 32.5974 23.7726 30.8866C21.9999 29.2458 20.4794 27.3519 19.2605 25.2663C18.7837 24.454 19.2075 24.0125 19.6137 23.6107C20.0199 23.2089 20.426 22.6615 20.8322 22.1891C21.1666 21.7785 21.4402 21.3221 21.6446 20.8337C21.7524 20.6098 21.8028 20.3627 21.7913 20.1145C21.7797 19.8663 21.7065 19.6249 21.5784 19.412C21.3753 19.0058 19.8124 14.997 19.0751 13.3855"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_12564_269349"
				x1="27.0611"
				y1="3.2362"
				x2="27.3348"
				y2="49.3863"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#57D163" />
				<stop offset="1" stopColor="#23B33A" />
			</linearGradient>
		</defs>
	</svg>
)

export default WhatsApp
