import {Locales} from "../../../localization/types";

export const getHeadingAndTextarea = (locale :Locales) => {
  if (locale === "kz-KZ") {
    return {
      subheading: 'Төлеу, жеткізу және қайтару шарттары',
      heading: 'Таңертең ақша, кешкісін орындықтар',
      headingType: 'primary',
    }
  }

  return {
    subheading: 'Условия оплаты, доставки и возврата',
    heading: 'Утром деньги, вечером стулья',
    headingType: 'primary',
  }
}
export const headingAndTextarea = {
  subheading: 'Условия оплаты, доставки и возврата',
  heading: 'Утром деньги, вечером стулья',
  headingType: 'primary',
}
