import React from "react"

import { Link } from "gatsby"

import MoscowPoints from "./assets/MoscowPoints"
import RussiaPoints from "./assets/RussiaPoints"
import KazakhstanPoints from "./assets/KazakhstanPoints"
import AlmatyPoints from "./assets/AlmatyPoints"
import BelarusPoints from "./assets/BelarusPoints"
import MinskPoints from "./assets/MinskPoints"

import { pagesLinks, externalLinks } from "../../../_V2/common/links"

import styles from "./styles.module.scss"
import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			heading: "Доставка",
			textarea:
				"Напиши нам или позвони — мы поможем подобрать транспортную компанию и рассчитаем стоимость доставки.",
			points: [
				{
					id: 0,
					mediaContent: <KazakhstanPoints />,
					heading: "В любую точку Казахстана",
					textarea: "Лично в руки по адресу, срок доставки — от 2 до 4 дней.",
					content: (
						<ul className={styles.ul}>
							<li>
								Стоимость для РК и&nbsp;СНГ рассчитывается автоматически,
								согласно тарифам транспортной&nbsp;компании.
							</li>
							<li>
								Безналичная оплата, путем перечисления денежных средств на расчетный
								счёт. 100%&nbsp;предоплата.
							</li>
						</ul>
					),
				},
				{
					id: 1,
					mediaContent: <AlmatyPoints />,
					heading: "Пункт выдачи заказов в Алматы",
					textarea: (
						<>
							Приезжай в{" "}
							<Link to={pagesLinks.contacts.href} className={styles.link}>
								наш офис
							</Link>
							, чтобы забрать заказ. Заодно поможем с&nbsp;выбором оборудования.
						</>
					),
					content: (
						<ul className={styles.ul}>
							<li>
								Выдача заказов производится с&nbsp;10:00 до&nbsp;18:00 с&nbsp;понедельника
								по&nbsp;пятницу (по рабочим дням)
							</li>
							<li>
								Проконсультируем по работе оборудования, выполним предварительную
								настройку, проведем диагностику
							</li>
						</ul>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			heading: "Жеткізу",
			textarea:
				"Бізге жазыңыз немесе қоңырау шалыңыз - біз көлік компаниясын таңдауға көмектесеміз және жеткізу құнын есептейміз.",
			points: [
				{
					id: 0,
					mediaContent: <KazakhstanPoints />,
					heading: "Қазақстанның кез келген нүктесіне",
					textarea: "Мекенжай бойынша жеке қолға, жеткізу мерзімі - 2-4 күн.",
					content: (
						<ul className={styles.ul}>
							<li>
								ҚР және ТМД үшін құн автоматты түрде, көлік компаниясының тарифтеріне сай есептеледі.
							</li>
							<li>
								Қолма-қол ақшасыз төлеу, ақша қаражатын есеп айырысу шотына аудару арқылы. 100% алдын ала төлеу.
							</li>
						</ul>
					),
				},
				{
					id: 1,
					mediaContent: <AlmatyPoints />,
					heading: "Алматыдағы тапсырыстарды беру орны",
					textarea: (
						<>
							Тапсырысты әкету үшін{" "}
							<Link to={pagesLinks.contacts.href} className={styles.link}>
								біздің офиске
							</Link>
							келіңіз. Сонымен бірге жабдықты таңдауға көмектесеміз.
						</>
					),
					content: (
						<ul className={styles.ul}>
							<li>
								Тапсырыстарды беру 10:00-ден 18:00-ге дейін дүйсенбіден жұмаға дейін (жұмыс күндері) жүргізіледі
							</li>
							<li>
								Жабдықтың жұмысы бойынша кеңес береміз, алдын ала теңшеуді орындаймыз, диагностика жүргіземіз
							</li>
						</ul>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			heading: "Доставка",
			textarea:
				"Напиши нам или позвони — мы поможем подобрать транспортную компанию и рассчитаем стоимость доставки.",
			points: [
				{
					id: 0,
					mediaContent: <BelarusPoints />,
					heading: "В любую точку Беларуси",
					textarea: "Лично в руки по адресу, срок доставки — от 2 до 4 дней.",
					content: (
						<ul className={styles.ul}>
							<li>
								Стоимость для РБ и СНГ рассчитывается автоматически,
								согласно тарифам транспортных&nbsp;компаний.
							</li>
							<li>
								Безналичная оплата, путем перечисления денежных средств на расчетный
								счёт. 100%&nbsp;предоплата.
							</li>
						</ul>
					),
				},
				{
					id: 1,
					mediaContent: <MinskPoints />,
					heading: "Пункт выдачи заказов в Минске",
					textarea: (
						<>
							Приезжай в{" "}
							<Link to={pagesLinks.contacts.href} className={styles.link}>
								наш офис
							</Link>
							, чтобы забрать заказ. Заодно поможем с&nbsp;выбором оборудования.
						</>
					),
					content: (
						<ul className={styles.ul}>
							<li>
								Выдача заказов производится с&nbsp;10:00 до&nbsp;18:00 с&nbsp;понедельника
								по&nbsp;пятницу (по рабочим дням)
							</li>
							<li>
								Проконсультируем по работе оборудования, выполним предварительную
								настройку, проведем диагностику
							</li>
						</ul>
					),
				},
			],
		}
	}

	return {
		heading: "Доставка",
		textarea:
			"Напиши нам или позвони — мы поможем подобрать транспортную компанию и рассчитаем стоимость доставки.",
		points: [
			{
				id: 0,
				mediaContent: <RussiaPoints />,
				heading: "В любую точку России",
				textarea: "Лично в руки по адресу, срок доставки — от 2 до 4 дней.",
				content: (
					<ul className={styles.ul}>
						<li>
							От 500 рублей за комплект оборудования. Стоимость для РФ и СНГ
							рассчитывается автоматически, согласно тарифам транспортной
							компании.
						</li>
						<li>
							Безналичная оплата, путем перечисления денежных средств на расчетный
							счёт. 100% предоплата.
						</li>
					</ul>
				),
			},
			{
				id: 1,
				mediaContent: <MoscowPoints />,
				heading: "Пункт выдачи заказов в Москве",
				textarea: (
					<>
						Приезжай в{" "}
						<Link to={pagesLinks.contacts.href} className={styles.link}>
							наш офис
						</Link>
						, чтобы забрать заказ. Заодно поможем с выбором оборудования.
					</>
				),
				content: (
					<ul className={styles.ul}>
						<li>
							Выдача заказов производится с 10:00 до 18:00 с понедельника
							по пятницу (по рабочим дням)
						</li>
						<li>
							Проконсультируем по работе оборудования, выполним предварительную
							настройку, проведем диагностику
						</li>
					</ul>
				),
			},
		],
	}
}
