import React from 'react'

import Phone from './assets/Phone'
import Email from './assets/Email'
import WhatsApp from './assets/WhatsApp'
import Telegram from './assets/Telegram'

import { contacts, contactsKz, contactsBy } from '../../../_V2/common/contacts'
import { Locales } from '../../../../localization/types';

import styles from '../../../../components/_V3/ConnectWithUs/styles.module.scss'


export const getData = (locale: Locales) => {
    if (locale === 'ru-KZ') {
        return {
            subheading: 'Стоимость доставки',
            heading: 'Давай  посчитаем',
            list: [
                {
                    id: 0,
                    mediaContent: <Phone />,
                    link: {
                        text: (
                            <>
                                <span className={styles.phone}>{contactsKz.phone.text}</span>
                                <br />
                                Позвонить
                            </>
                        ),
                        url: `tel:${contactsKz.phone.value}`,
                    },
                },
                {
                    id: 1,
                    mediaContent: <Email />,
                    link: {
                        text: (
                            <>
                                Написать
                                <br />
                                на эл. почту
                            </>
                        ),
                        url: `mailto:${contactsKz.salesEmail}`,
                    },
                },
                {
                    id: 2,
                    mediaContent: <WhatsApp />,
                    link: {
                        text: (
                            <>
                                Написать
                                <br />
                                в WhatsApp
                            </>
                        ),
                        // Пока нет информации об отдельном whatsApp для локализаций
                        url: contacts.whatsApp,
                    },
                },
                {
                    id: 3,
                    mediaContent: <Telegram />,
                    link: {
                        text: (
                            <>
                                Написать
                                <br />
                                в Telegram
                            </>
                        ),
                        // Пока нет информации об отдельном telegram для локализаций
                        url: contacts.telegram,
                    },
                },
            ],
        }
    }

    if (locale === 'kz-KZ') {
        return {
            subheading: 'Жеткізу құны',
            heading: 'Есептейік',
            list: [
                {
                    id: 0,
                    mediaContent: <Phone />,
                    link: {
                        text: (
                            <>
                                <span className={styles.phone}>{contactsKz.phone.text}</span>
                                <br />
                                Қоңырау
                            </>
                        ),
                        url: `tel:${contactsKz.phone.value}`,
                    },
                },
                {
                    id: 1,
                    mediaContent: <Email />,
                    link: {
                        text: (
                            <>
                                Эл. поштаға
                                <br />жазу
                            </>
                        ),
                        url: `mailto:${contactsKz.salesEmail}`,
                    },
                },
                {
                    id: 2,
                    mediaContent: <WhatsApp />,
                    link: {
                        text: (
                            <>
                                WhatsApp-қа<br />
                                жазу
                            </>
                        ),
                        // Пока нет информации об отдельном whatsApp для локализаций
                        url: contacts.whatsApp,
                    },
                },
                {
                    id: 3,
                    mediaContent: <Telegram />,
                    link: {
                        text: (
                            <>
                                Telegram-ға <br />
                                жазу
                            </>
                        ),
                        // Пока нет информации об отдельном telegram для локализаций
                        url: contacts.telegram,
                    },
                },
            ],
        }
    }

    if (locale === 'ru-BY') {
        return {
            subheading: 'Стоимость доставки',
            heading: 'Давай  посчитаем',
            list: [
                {
                    id: 0,
                    mediaContent: <Phone />,
                    link: {
                        text: (
                            <>
                                <span className={styles.phone}>{contactsBy.phone.text}</span>
                                <br />
                                Позвонить
                            </>
                        ),
                        url: `tel:${contactsBy.phone.value}`,
                    },
                },
                {
                    id: 1,
                    mediaContent: <Email />,
                    link: {
                        text: (
                            <>
                                Написать
                                <br />
                                на эл. почту
                            </>
                        ),
                        url: `mailto:${contactsBy.infoEmail}`,
                    },
                },
                {
                    id: 2,
                    mediaContent: <WhatsApp />,
                    link: {
                        text: (
                            <>
                                Написать
                                <br />
                                в WhatsApp
                            </>
                        ),
                        // Пока нет информации об отдельном whatsApp для локализаций
                        url: contacts.whatsApp,
                    },
                },
                {
                    id: 3,
                    mediaContent: <Telegram />,
                    link: {
                        text: (
                            <>
                                Написать
                                <br />
                                в Telegram
                            </>
                        ),
                        // Пока нет информации об отдельном telegram для локализаций
                        url: contacts.telegram,
                    },
                },
            ],
        }
    }

    return {
        subheading: 'Стоимость доставки',
        heading: 'Давай  посчитаем',
        list: [
            {
                id: 0,
                mediaContent: <Phone />,
                link: {
                    text: (
                        <>
                            <span className={styles.phone}>8 800 500-21-38</span>
                            <br />
                            Позвонить
                        </>
                    ),
                    url: `tel:${contacts.phone.value}`,
                },
            },
            {
                id: 1,
                mediaContent: <Email />,
                link: {
                    text: (
                        <>
                            Написать
                            <br />
                            на эл. почту
                        </>
                    ),
                    url: `mailto:${contacts.salesEmail}`,
                },
            },
            {
                id: 2,
                mediaContent: <WhatsApp />,
                link: {
                    text: (
                        <>
                            Написать
                            <br />
                            в WhatsApp
                        </>
                    ),
                    url: contacts.whatsApp,
                },
            },
            {
                id: 3,
                mediaContent: <Telegram />,
                link: {
                    text: (
                        <>
                            Написать
                            <br />
                            в Telegram
                        </>
                    ),
                    url: contacts.telegram,
                    rel: "nofollow"
                },
            },
        ],
    }
}
